import React, { Component } from 'react';
import './App.css';
import { Route, BrowserRouter,Switch } from "react-router-dom";
import Navbar from './Components/Navbar';
import Drawer from './Components/Drawer';
import Detail from './Views/Detail';
import DetailCustomer from './Views/DetailCustomer';
import Login from './Views/login';
import { Layout } from "antd";
import Distributor from './Views/Distributor';
import RedeemCheck from './Views/RedeemCheck';
import RedeemMakan from './Views/RedeemMakan';

const { Content } = Layout;

class App extends Component {
  render(){
    return(
      <div className="App" history={this.props.history}>
        <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
            <Switch >
              <Route exact path="/" component={Login}/>
                <Layout style={{ minHeight: "100vh" }}>
                  <Drawer history={this.props.history}/>
                  <Layout>
                    <Navbar/>
                  <Content style={{ margin: '0 16px' }}>
                    <Route path="/Distributor" component={Distributor}/>
                    <Route path="/detail" component={Detail}/>
                    <Route path="/detail-customer" component={DetailCustomer}/>
                    <Route path="/redeem" component={RedeemCheck}/>
                    <Route path="/redeem-makan" component={RedeemMakan}/>
                  </Content>
              </Layout>
          </Layout>
            </Switch>  
        </BrowserRouter>
      </div>
    );
  }
}
export default App;
