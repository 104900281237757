import React, { Component } from "react";
import { Button } from "antd";

export default class DetailColumnCustomer extends Component {
    state = {
        userData: {},
        loading:false
    }
    onDetail = () => {
        this.setState({ loading: true });
        const axios = require('axios');
        axios.get("https://oapi-rv.anterin.id//api/v1/poi/redeem/customers/" + this.props.data.id,
           ).then(response => {
                console.log('response: ', response.data.data);
                this.props.history.push(
                    {
                        pathname: "/detail-customer",
                        state: response.data.data
                    }
                )
            }).catch(function (error) {
                console.log(error);
            });
    }


    render() {
        return (
            <div>
                <Button type="primary" 
                    onClick={this.onDetail}
                    loading={this.state.loading}
                >Detail</Button>
            </div>
        );
    }
}