import React, { Component } from 'react';
import { Layout, Table, Breadcrumb, Descriptions, Icon } from 'antd';
import axios from 'axios';
import { Link } from "react-router-dom";
import DetailColumn from "./DetailColumnCustomer";

const { Content, Footer } = Layout;
const { Column } = Table;

export default class Detail extends Component {
    constructor(props) {
        super(props)
        let modifiedData = props.location.state
        console.log("TES PASSING DATA", props.location.state)
        this.state = {
            data : [],
            drivers:[],
            loading:false,
            dataFromOtherComponent : modifiedData,
            pagination : {
              current : 1
            },
        };   
    }

    handleTableChange = (pagination) => {
      const pager = { ...this.state.pagination };
      console.log("PAGER", pager);
      pager.current = pagination.current;
      console.log("current ", pager.current)
      this.setState({
        ...this.state,
        pagination: pager
      },() => this.fetch());    
    }

    componentDidMount(){
        this.fetch();
    }

      fetch = () => {
        this.setState({ 
          ...this.state,
          loading: true });
        axios.get(
          "https://oapi-rv.anterin.id/api/v1/poi/redeem/distributors/" + this.props.location.state.id + '/customers?limit=10' + "&page=" + this.state.pagination.current,
          {
          headers : {
            Authorization: "Bearer "+ localStorage.getItem("token")
          }
        }).then(response => {
          console.log(response);
          const pagination = { ...this.state.pagination };
          pagination.total = response.data.pagination.total;
          console.log('total :', pagination.total)
          var newArray = [];
          response.data.data.forEach(item => {
            item.key = item.id;
            newArray.push(item);
          });
          this.setState({
            ...this.state,
            data: newArray,
            loading: false,
            pagination,
          });
        })
        .catch(function(error) {
          console.log(error);
        })
      }
    render(){
        return (            
              <Layout className="layout">
                <Content style={{ padding: '0 50px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Distributor</Breadcrumb.Item>
                    <Breadcrumb.Item>Detail</Breadcrumb.Item>
                    </Breadcrumb>
                    <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
                    <Link to="/">
                        <div style={{fontSize:20, color:'black', display:"inline-block", marginBottom:20}}>
                        <Icon type="arrow-left"/>
                        <span style={{fontWeight:"bold", padding:10}}>Distributor Info</span>
                        </div>
                    </Link>    
                    <Descriptions title="" size="small" column={2}>
                        <Descriptions.Item label="name">{this.state.dataFromOtherComponent.name}                    </Descriptions.Item>
                        <Descriptions.Item label="created_at">{this.state.dataFromOtherComponent.created_at}            </Descriptions.Item>
                        <Descriptions.Item label="email">{this.state.dataFromOtherComponent.email}                    </Descriptions.Item>
                        <Descriptions.Item label="telp">{this.state.dataFromOtherComponent.telp}             </Descriptions.Item>
                        <Descriptions.Item label="phone">{this.state.dataFromOtherComponent.phone}                   </Descriptions.Item>
                        <Descriptions.Item label="address">{this.state.dataFromOtherComponent.address}                 </Descriptions.Item>
                    </Descriptions>

                    <h3 style={{fontWeight:"bold", paddingTop:50}}>Costumers List   </h3>
                        <Table 
                          onChange={this.handleTableChange}
                          loading={this.state.loading}
                          dataSource={this.state.data}
                          pagination={this.state.pagination} 
                         >
                            <Column title="name" dataIndex="name"  />
                            <Column title="phone" dataIndex="phone"  />
                            <Column title="email" dataIndex="email"  />
                            {/* <Column title="created at" dataIndex="created_at"/> */}
                            <Column title="total points" dataIndex="total_points"  />
                            {/* <Column title="redeemed points" dataIndex="redeemed_points"  />
                            <Column title="remaining points" dataIndex="remaining_points"  /> */}
                            <Column title="detail" dataIndex="detail" 
                                render={
                                (unused1,obj,unused2) => <DetailColumn history={this.props.history} data={obj} />
                            }/>
                           
                        </Table>
                    </div>
                    <Footer></Footer>
                </Content>
            </Layout>
        )
    }
}