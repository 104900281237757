import React, { Component } from 'react';
import { Layout, Table, Breadcrumb, Divider, Input, Icon, Button, Tag, Dropdown, Menu } from 'antd';
import axios from 'axios';
import { URL } from '../Components/BaseUrl';
import Approval from './ApprovalMakan';

const { Content, Footer } = Layout;
const { Column } = Table;
const { Search } = Input

export default class RedeemMakan extends Component {
  constructor(props){
    super(props);
    if(localStorage.getItem("token") == null){
      this.props.history.push('/')
    }
  }
    state = {
      data : [],
      pagination : {
        current : 1
      },
      loading : false,
      searchValue: '',
      totalDataTableValue: 10,
      status: '',
      sortBy: '',
      orderBy: 'asc'
    };
    
    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        console.log("PAGER", pager);
        pager.current = pagination.current;
        pager.pageSize = this.state.totalDataTableValue;
        console.log("current ", pager.current)
        this.setState({
          ...this.state,
          pagination: pager
        },() => this.fetch());    
      }

      handleMenuClick = (e) => {
        this.setState({
          ...this.state,
          totalDataTableValue: e.key,
        },() => this.fetch());
      }

      handleStatusClick = (e) => {
        this.setState({
          ...this.state,
          status: e.key,
        },() => this.fetch());
      }

      handleSortClick = (e) => {
        this.setState({
          ...this.state,
          sortBy: e.key.toString(),
        },() => this.fetch());
      }

      handleOrderClick = (e) => {
        this.setState({
          ...this.state,
          orderBy: e.key,
        }, () => this.fetch());
      }

      onClearClick = (e) => {
        this.setState({
          ...this.state,
          status: "",
          sortBy:"",
        }, () => this.fetch());
      }
    
      componentDidMount() {
        this.fetch();
      }


    getSearchValue = (e) => {
        this.setState({
          ...this.state,
          searchValue: e.target.value
        })
        console.log(e.target.value)
      }

      onClicked = () => {
        console.log(this)
        this.fetch(this.state.searchValue)
      }
    
      fetch = () => {
        this.setState({ 
          ...this.state,
          loading: false });
        axios.get(
          URL + "/api/v1/finance/cashout?page=" + this.state.pagination.current + "&limit=" + this.state.totalDataTableValue + 
                "&search=" + this.state.searchValue + "&status=" + this.state.status +"&sort_by=" +this.state.sortBy + "&order_by=" + this.state.orderBy,
          {
            headers : {
              Authorization: "Bearer "+ localStorage.getItem("token")
            } 
          }).then(response => {
          console.log(response.data.response);
          const pagination = { ...this.state.pagination };
          pagination.total = response.data.meta.totalPages;
          pagination.pageSize = this.state.totalDataTableValue;
          var newArray = [];
          response.data.data.forEach(item => {
            var date = item.created_at
            var res = date.substr(0, 19);
            item.key = item.id;
            item.created_at = res;
            if ( item.status === 0 ) {
              item.status = 'Waiting For Approval'
            } else if ( item.status === 1 ) {
              item.status = 'Approved'
            } else {
              item.status = 'Rejected'
            }

            if ( item.bank_code !== null ) {
                if(item.bank_code === "008" ){
                    item.bank_code = "MANDIRI"
                }
            } else {
              item.bank_code ='-'
            }

            item.bank_name = item.bank.name

            newArray.push(item);
          });
          this.setState({
            ...this.state,
            data: newArray,
            loading: false,
            pagination,
          });
        })
        .catch(function(error) {
          console.log(error);
        })
      }

      renderButton = (record) => {
        if( record.status === "Waiting For Approval" ){
            return <Approval fetchData={this.fetch} history={this.props.history} id={record.id}  />
        } else if (record.status === "Approved" ) {
          return <Button disabled={true}> Approved </Button> 
        } else{
          return <Button disabled={true}> Rejected </Button>
        }
      }

    render(){
      const menu  = (
        <Menu onClick={this.handleMenuClick}>
          <Menu.Item key={10}>
            10
          </Menu.Item>
          <Menu.Item key={20}>
            20
          </Menu.Item>
          <Menu.Item key={50}>
            50
          </Menu.Item>
        </Menu>
      );

      const status  = (
        <Menu onClick={this.handleStatusClick}>
          <Menu.Item key={0}>
            Waiting
          </Menu.Item>
          <Menu.Item key={1}>
            Approved
          </Menu.Item>
          <Menu.Item key={2}>
            Rejected
          </Menu.Item>
        </Menu>
      );

      const sortBy  = (
        <Menu onClick={this.handleSortClick}>
          <Menu.Item key={'created_at'}>
            Created At
          </Menu.Item>
          <Menu.Item key={'updated_at'}>
            Updated At
          </Menu.Item>
        </Menu>
      );

      const orderBy  = (
        <Menu onClick={this.handleOrderClick}>
          <Menu.Item key={'asc'}>
            Newest
          </Menu.Item>
          <Menu.Item key={'desc'}>
            Latest
          </Menu.Item>
        </Menu>
      );

        return (            
              <Layout className="layout">
                  <Content style={{ padding: '0px 0px', }}>
                    <Breadcrumb className="navbar" style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item> 
                      <Icon type="home" />
                      <span>Home</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Redeem</Breadcrumb.Item>
                    </Breadcrumb>
                    
                    <div style={{ background: '#fff', padding: 24, minHeight: 580, borderRadius:'10px'  }}>         
                      
                      <Dropdown.Button style={{paddingRight:10}} overlay={status} icon={<Icon type="caret-down" />}>
                        Status
                      </Dropdown.Button>  
                      
                      <Dropdown.Button style={{paddingRight:10}} overlay={sortBy} icon={<Icon type="caret-down" />}>
                        Sort By
                      </Dropdown.Button>  

                      <Dropdown.Button style={{paddingRight:10}} overlay={orderBy} icon={<Icon type="caret-down" />}>
                        Order By
                      </Dropdown.Button>  
                                          
                      <Button st type="primary" onClick={this.onClearClick} >Clear</Button>
                      
                      <Search 
                      style={{width:250, float:'right', paddingBottom:20, paddingLeft:20}}
                      placeholder="input phone number" 
                      onChange={(e) => this.getSearchValue(e)} 
                      onSearch={() => this.onClicked()}
                      enterButton />
                      
                      <Dropdown.Button style={{float:'right'}} overlay={menu} icon={<Icon type="caret-down" />}>
                        {this.state.totalDataTableValue}
                      </Dropdown.Button>

                      <p className="navbar" style={{float:'right', margin:5}}>Per Page :</p>
                      

                      <Divider/>

                      

                      <br/>

                      <Table
                        onChange={this.handleTableChange}
                        dataSource={this.state.data}
                        pagination={this.state.pagination} 
                        loading={this.state.loading}
                      >
                          <Column title="User Type" dataIndex="user_type"  />
                          <Column title="Amount" dataIndex="amount"/>
                          <Column title="fee" dataIndex="fee"/>                                                              
                          <Column 
                            title="Status" 
                            key="status"
                            render = {(text, record) => {
                              let color = "magenta";
                              if (record.status === 'Approved'){
                              color = 'geekblue'
                            } else if( record.status === 'Waiting For Approval' ){
                              color = 'green'
                            } else {
                              color = 'volcano'
                            }
                              return <Tag color={color}> {record.status} </Tag>}}
                          />
                          <Column title="Phone Number" dataIndex="phone"  />
                          <Column title="Created At" dataIndex="created_at"/>
                          <Column title="Updated At" dataIndex="updated_at"/>
                          <Column title="Bank" dataIndex="bank_name"/>
                          <Column title="Bank Account" dataIndex="bank_account_number"/>
                          <Column
                            title="Action"
                            key="action"
                            render={(text, record) => this.renderButton(record)}
                          />
                      </Table>
                    </div>
                    <Footer></Footer>
                </Content>
            </Layout>
        )
    }
}