import React, { Component } from 'react';
import { Layout, Table, Breadcrumb, Divider, Input, Icon, Button, Tag, Dropdown, Menu } from 'antd';
import axios from 'axios';
import { URL } from '../Components/BaseUrl';
import Approval from './Approval';

const { Content, Footer } = Layout;
const { Column } = Table;
const { Search } = Input

export default class RedeemCheck extends Component {
  constructor(props){
    super(props);
    if(localStorage.getItem("token") == null){
      this.props.history.push('/')
    }
  }
    state = {
      data : [],
      pagination : {
        current : 1
      },
      loading : false,
      searchValue: '',
      totalDataTableValue: 10
    };

    
    
    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        console.log("PAGER", pager);
        pager.current = pagination.current;
        pager.pageSize = this.state.totalDataTableValue;
        console.log("current ", pager.current)
        this.setState({
          ...this.state,
          pagination: pager
        },() => this.fetch());    
      }

      handleMenuClick = (e) => {
        this.setState({
          ...this.state,
          totalDataTableValue: e.key,
        },() => this.fetch());
      }
    
      componentDidMount() {
        this.fetch();
      }


    getSearchValue = (e) => {
        this.setState({
          ...this.state,
          searchValue: e.target.value
        })
        console.log(e.target.value)
      }

      onClicked = () => {
        console.log(this)
        this.fetch(this.state.searchValue)
      }
    
      fetch = () => {
        this.setState({ 
          ...this.state,
          loading: false });
        axios.get(
          URL + "/api/v1/redeemCheck/list?page=" + this.state.pagination.current + "&limit=" + this.state.totalDataTableValue + "&search" + this.state.searchValue,
          {
            headers : {
              Authorization: "Bearer "+ localStorage.getItem("token")
            } 
          }).then(response => {
          console.log(response.data.response);
          const pagination = { ...this.state.pagination };
          console.log("pagination", pagination)
          pagination.total = 500;
          pagination.pageSize = this.state.totalDataTableValue;
          var newArray = [];
          response.data.response.data.forEach(item => {
            var date = item.created_at
            var res = date.substr(0, 19);
            item.key = item.id;
            item.created_at = res
            if ( item.redeem_type === 1 ) {
              item.redeem_type = 'Redeem A-Poin'
            } else {
              item.redeem_type = 'Prize Redeem'
            }

            if ( item.status === 0 ) {
              item.status = 'Waiting For Approval'
            } else if ( item.status === 1 ) {
              item.status = 'Approved'
            } else {
              item.status = 'Rejected'
            }

            if ( item.convert.bank_name !== null ) {
              item.bank_name = item.convert.bank_name
            } else {
              item.bank_name = '-'
            }

            if ( item.convert.bank_account !== null ) {
              item.bank_account = item.convert. bank_account
            } else {
              item.bank_account ='-'
            }

            newArray.push(item);
          });
          this.setState({
            ...this.state,
            data: newArray,
            loading: false,
            pagination,
          });
        })
        .catch(function(error) {
          console.log(error);
        })
      }

      renderButton = (record) => {
        if( record.status === "Waiting For Approval" ){
            return <Approval history={this.props.history} id={record.id} />
        } else if (record.status === "Approved" ) {
          return <Button disabled={true}> Approved </Button> 
        } else{
          return <Button disabled={true}> Rejected </Button>
        }
      }

    render(){
      const menu  = (
        <Menu onClick={this.handleMenuClick}>
          <Menu.Item key={10}>
            10
          </Menu.Item>
          <Menu.Item key={20}>
            20
          </Menu.Item>
          <Menu.Item key={50}>
            50
          </Menu.Item>
        </Menu>
      );
        return (            
              <Layout className="layout">
                  <Content style={{ padding: '0px 0px', }}>
                    <Breadcrumb className="navbar" style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item> 
                      <Icon type="home" />
                      <span>Home</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Redeem</Breadcrumb.Item>
                    </Breadcrumb>
                    <div style={{ background: '#fff', padding: 24, minHeight: 580, borderRadius:'10px'  }}>         
                      <p className="navbar" style={{float:'left', margin:5}}>Per Page :</p>
                      <Dropdown.Button overlay={menu} icon={<Icon type="caret-down" />}>
                        {this.state.totalDataTableValue}
                      </Dropdown.Button>
                      <Search style={{maxWidth:300, float:'right', paddingBottom:20}} placeholder="input search text" onSearch={value => console.log(value)} enterButton />

                      <Divider/>

                      <Table
                        onChange={this.handleTableChange}
                        dataSource={this.state.data}
                        pagination={this.state.pagination} 
                        loading={this.state.loading}
                      >
                          <Column title="Name" dataIndex="name"  />
                          {/* <Column title="User Type" dataIndex="user_type"  /> */}
                          <Column title="Points" dataIndex="points"  />
                          <Column title="Redeem Type" dataIndex="redeem_type"/>                               
                          <Column 
                            title="Status" 
                            key="status"
                            render = {(text, record) => {
                              let color = "magenta";
                              if (record.status === 'Approved'){
                              color = 'geekblue'
                            } else if( record.status === 'Waiting For Approval' ){
                              color = 'green'
                            } else {
                              color = 'volcano'
                            }
                              return <Tag color={color}> {record.status} </Tag>}}
                          />
                          <Column title="Created At" dataIndex="created_at"/>
                          <Column title="Phone Number" dataIndex="phone"  />
                          <Column title="Bank Name" dataIndex="bank_name"/>
                          <Column title="Bank Account" dataIndex="bank_account"/>
                          <Column
                            title="Action"
                            key="action"
                            render={(text, record) => this.renderButton(record)}
                          />
                      </Table>
                    </div>
                    <Footer></Footer>
                </Content>
            </Layout>
        )
    }
}