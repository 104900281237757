import React, { Component } from "react";
import { Layout, Icon, Menu, Modal } from "antd";
import { Link, withRouter } from "react-router-dom";

const { Sider } = Layout;
const { confirm } = Modal;

class Drawer extends Component {
  state = {
    collapsed: false,
  }

  

  //clear token from localStoragecc
  onOkay = () => {  
    localStorage.clear();
    this.props.history.push('/')
    console.log('token:', localStorage.clear() )
  }

  //for pop-up logout confirmation
  showConfirm = () => {
    confirm({
      title: 'Logout',
      content: 'are you sure want to logout?',
      onOk: () => this.onOkay(),
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  //sidebar span
  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  render() {
    console.log("HISTORYY2: ", this.props.history)
    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}>
        <div style={{ alignContent: "center" }} className="logo">
         
        </div>
        <Menu theme="dark" mode="inline" >
          {/* <Menu.Item key="1">
            <Link to="/redeem">
              <Icon type="user" />
              <span>Redeem</span>
            </Link>
          </Menu.Item> */}
          <Menu.Item key="2">
            <Link to="/Distributor">
              <Icon type="shop" />
              <span>Distributor</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/redeem-makan">
              <Icon type="user" />
              <span>Approval</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="9" onClick={this.showConfirm}>
              <Icon type="logout" />
              <span>Logout</span>
          </Menu.Item>

          
        </Menu>
      </Sider>
    );
  }
}

export default withRouter(Drawer);
