import React, { Component } from 'react';
import { URL } from '../Components/BaseUrl';
import { Button, message, Icon, Drawer, Col, Row } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const DescriptionItem = ({ title, content }) => (
    <div
      className="site-description-item-profile-wrapper"
      style={{
        fontSize: 14,
        lineHeight: '22px',
        marginBottom: 7,
      }}
    >
      <p
        className="site-description-item-profile-p"
        style={{
          marginRight: 8,
          display: 'inline-block',
        }}
      >
        {title}:
      </p>
      {content}
    </div>
  );

export default class Approval extends Component {
    constructor(props) {
        super(props)
        this.state = {
                userData: {},
                loadingApprove: false,
                loadingReject: false,
                visible: false,
                data:{},
                bank:''
            }
        }   
    

    onApproved = () => {
        this.setState({ loadingApprove: true });
        const axios = require('axios');
        console.log('props', this.props.id)
        axios.post( URL  +"//api/v1/finance/cashout/" + this.props.id + "/approve",
        {},
        {
            headers : {
                Authorization: "Bearer "+ localStorage.getItem("token")
            }
        }
           ).then(response => {
                console.log('response: ', response.data);
                this.props.fetchData()
                this.props.history.push(
                    {
                        state: response.data.data
                    }
                )
                this.setState({ 
                    ...this.state, 
                    loadingApprove:false
                })
                message.warning(response.data.message)
            }).catch = (error) => {
                console.log(error.response);
                this.setState({ 
                    ...this.state, 
                    loadingApprove:false})
                message.warning(error.response.message)
                window.location.reload();
            };
    }

    onRejected = () => {
        this.setState({ loadingReject: true });
        const axios = require('axios');
        axios.post( URL  +"/api/v1/finance/cashout/" + this.props.id + "/reject",{},    
        {
                headers : {
                    Authorization: "Bearer "+ localStorage.getItem("token")
                }
            }
           ).then(response => {
            console.log('response: ', response.data);
            this.props.fetchData()
            this.props.history.push(
                {
                    state: response.data.data
                }
            )
            this.setState({ 
                ...this.state, 
                loadingReject:false
            })
            message.warning(response.data.message)
            window.location.reload();               
        }).catch = (error) => {
            console.log(error.response);
            this.setState({ 
                ...this.state, 
                loadingReject:false})
            message.warning(error.response.message)
        };
    }

    showDrawer = () => {
        this.setState({
          visible: true,
        });
        const axios = require('axios');
        axios.get( URL + "/api/v1/finance/cashout/" + this.props.id,
            { 
                headers : {
                    Authorization: "Bearer "+ localStorage.getItem("token")
                }
            }    
        ).then(response => {
            console.log(response);
            const item = response.data.data;
            console.log('response', item)
            this.setState({
              ...this.state,
              data: item,
              bank: item.bank.name
            });
            console.log("data", this.state.data)
          })
          .catch(function(error) {
            console.log(error);
          })
        }
      
    
      onClose = () => {
        this.setState({
          visible: false,
        });
      };

    
    render() {
        
        return (
            <div>
                <Button     
                    style={{marginRight:5}}
                    type="primary" 
                    onClick={this.onApproved}
                    loading={this.state.loadingApprove}>
                    <Icon type="check"></Icon>
                </Button>
                <Button 
                    style={{marginRight:5}}
                    type="danger" 
                    onClick={this.onRejected}
                    loading={this.state.loadingReject}>
                    <Icon type="close"></Icon>
                </Button>
                <Button style={{backgroundColor:'#00a65a', color:'white'}} onClick={this.showDrawer}    >
                    <EyeOutlined />
                </Button>

                <Drawer
                    width={640}
                    title="Basic Drawer"
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    >
                    <Row>
                        <Col span={12}>
                        <DescriptionItem title="Phone" content={this.state.data.phone} />
                        </Col>
                        <Col span={12}>
                        <DescriptionItem title="User Type" content={this.state.data.user_type} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                        <DescriptionItem title="Amount" content={this.state.data.amount} />
                        </Col>
                        <Col span={12}>
                        <DescriptionItem title="Fee" content={this.state.data.fee} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                        <DescriptionItem title="Bank" content={this.state.bank} />
                        </Col>
                        <Col span={12}>
                        <DescriptionItem title="Bank Account Name" content={this.state.data.bank_account_name} />
                        </Col>
                        <Col span={12}>
                        <DescriptionItem title="Bank Account Number" content={this,this.state.data.bank_account_number} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem title="Approved By" content={this.state.data.approved_by} />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem title="Approved At" content={this.state.data.approved_at} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem title="Rejected By" content={this.state.data.rejected_by} />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem title="Rejected At" content={this.state.data.approved_at} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem title="Created At" content={this.state.data.created_at} />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem title="Updated At" content={this.state.data.updated_at} />
                        </Col>
                    </Row>
                </Drawer>
            </div>
        );
    }
}