import React, { Component } from 'react';
import { Layout, Table, Breadcrumb, Divider, Input, Icon } from 'antd';
import axios from 'axios';
import DetailColumn from './DetailColumn';

const { Search } = Input
const { Content, Footer } = Layout;
const { Column } = Table;

export default class Distributor extends Component {
  constructor(props){
    super(props);
    if(localStorage.getItem("token") == null){
      this.props.history.push('/')
      console.log("login")
    }
  }
    state = {
        data : [],
        pagination : {
          current : 1
        },
        loading : false,
        searchValue: "",
      };
    
    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        console.log("PAGER", pager);
        pager.current = pagination.current;
        console.log("current ", pager.current)
        this.setState({
          ...this.state,
          pagination: pager
        },() => this.fetch());    
      }
    
      componentDidMount() {
        this.fetch();
      }


    getSearchValue = (e) => {
        this.setState({
          ...this.state,
          searchValue: e.target.value
        })
        console.log(e.target.value)
      }

      onClicked = () => {
        console.log(this)
        this.fetch(this.state.searchValue)
      }
    
      fetch = () => {
        this.setState({ 
          ...this.state,
          loading: true });
        axios.get(
          "https://oapi-rv.anterin.id/api/v1/poi/redeem/distributors?limit=10&page=" + this.state.pagination.current + "&search=" + this.state.searchValue,
        ).then(response => {
          console.log(response);
          const pagination = { ...this.state.pagination };
          pagination.total = response.data.pagination.total;
          var newArray = [];
          response.data.data.forEach(item => {
            item.key = item.id;
            newArray.push(item);
          });
          this.setState({
            ...this.state,
            data: newArray,
            loading: false,
            pagination,
          });
        })
        .catch(function(error) {
          console.log(error);
        })
      }



    render(){
        return (            
              <Layout className="layout">
                <Content style={{ padding: '0px 5px' }}>
                    <Breadcrumb className="navbar" style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                      <Icon type="home" />
                      <span>Home</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>List</Breadcrumb.Item>
                    <Breadcrumb.Item>Distributor</Breadcrumb.Item>
                    </Breadcrumb>
                    <div style={{ background: '#fff', padding: 24, minHeight: 280, borderRadius:'10px'  }}>         
                    
                    {/* <Input style={{width:200}}
                        prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="search text value"
                        onChange={(e) => this.getSearchValue(e)}
                    />
                     <Divider type="vertical"/>
                    <Button
                        type="primary"
                        shape="circle"
                        icon="search"
                        onClick={() => this.onClicked()}
                        loading={this.state.loading}
                    ></Button> */}

                    <Search 
                      style={{width:250, float:'right', paddingBottom:20}}
                      placeholder="input search text" 
                      onChange={(e) => this.getSearchValue(e)} 
                      onSearch={() => this.onClicked()}
                      enterButton />


                    <Divider/>
                        <Table
                         scroll={{ x: 1000 }}
                         onChange={this.handleTableChange}
                         dataSource={this.state.data}
                         pagination={this.state.pagination} 
                         loading={this.state.loading}>    
                            <Column title="name" dataIndex="name"  />
                            <Column title="phone" dataIndex="phone"  />
                            <Column title="address" dataIndex="address"  />
                            <Column title="created at" dataIndex="created_at"/>
                            <Column title="email" dataIndex="email"  />
                            <Column title="detail" dataIndex="detail" 
                                render={
                                (unused1,obj,unused2) => <DetailColumn history={this.props.history} data={obj} />
                            }/>
                        </Table>
                    </div>
                    <Footer></Footer>
                </Content>
            </Layout>
        )
    }
}