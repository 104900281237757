import React, { Component } from 'react';
import { Layout, Table, Breadcrumb, Descriptions, Icon } from 'antd';
import axios from 'axios';
import { Link } from "react-router-dom";

const { Content, Footer } = Layout;
const { Column } = Table;

export default class DetailCustomer extends Component {
    constructor(props) {
        super(props)
        let modifiedData = props.location.state
        console.log("TES PASSING DATA", props.location.state)
        this.state = {
            data : [],
            loading:false,
            dataFromOtherComponent : modifiedData,
            pagination : {
              current : 1
            },
        };

    }

    handleTableChange = (pagination) => {
      const pager = { ...this.state.pagination };
      console.log("PAGER", pager);
      pager.current = pagination.current;
      console.log("current ", pager.current)
      this.setState({
        ...this.state,
        pagination: pager
      },() => this.fetch());    
    }

    componentDidMount(){
        this.fetch();
    }

      fetch = () => {
        this.setState({ 
          ...this.state,
          loading: true });
        axios.get(
          "https://oapi-rv.anterin.id//api/v1/poi/redeem/customers/" + this.props.location.state.id + "?limit=10&page=" + this.state.pagination.current,
          {
        }).then(response => {
          console.log(response)
          var newArray = [];
          // const pagination = { ...this.state.pagination };
          // pagination.total = response.data.pagination.total;
          response.data.data.history_points.forEach(item => {
            item.key = item.id;
            if (item.is_claim === 0){
                item.is_claim = "not claimed"
            } else{
                item.is_claim = "claimed"
            }

            if (item.is_redeem === 0){
                item.is_redeem = "not redeemed"
            } else {
                item.is_redeem = "redeemed"
            }

            if (item.monthly_saldo_id % 2 === 0){
                item.monthly_saldo_id = "customer"
            } else {
                item.monthly_saldo_id = "driver"
            }
            newArray.push(item); 
          });
          this.setState({
            ...this.state,
            data: newArray,
            loading: false,
          });
        })
        .catch(function(error) {
          console.log(error);
        })
      }

    render(){
        return (            
              <Layout className="layout">
                <Content style={{ padding: '0 50px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Customer</Breadcrumb.Item>
                    <Breadcrumb.Item>Detail</Breadcrumb.Item>
                    </Breadcrumb>
                    <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
                    <Link to="/Distributor">
                        <div style={{fontSize:20, color:'black', display:"inline-block", marginBottom:20}}>
                        <Icon type="arrow-left"/>
                        <span style={{fontWeight:"bold", padding:10}}>Costumer Info</span>
                        </div>
                    </Link>    

                    <Descriptions title="" size="small" column={2}>
                        <Descriptions.Item label="name">{this.state.dataFromOtherComponent.name}                      </Descriptions.Item>
                        {/* <Descriptions.Item label="created at">{this.state.dataFromOtherComponent.created_at}            </Descriptions.Item> */}
                        <Descriptions.Item label="email">{this.state.dataFromOtherComponent.email}                    </Descriptions.Item>
                        <Descriptions.Item label="total points">{this.state.dataFromOtherComponent.total_points}      </Descriptions.Item>
                        <Descriptions.Item label="phone">{this.state.dataFromOtherComponent.phone}                    </Descriptions.Item>
                        <Descriptions.Item label="address">{this.state.dataFromOtherComponent.address}                </Descriptions.Item>
                         
                    </Descriptions>

                    <h3 style={{fontWeight:"bold", paddingTop:50}}>History Points   </h3>
                        <Table 
                         loading={this.state.loading}
                         dataSource={this.state.data}
                         pagination={this.state.pagination} 
                         >
                            <Column title="current point" dataIndex="curr_point"/>
                            <Column title="current token" dataIndex="curr_token"/>
                            <Column title="created at" dataIndex="created_at"/>
                            <Column title="updated at" dataIndex="updated_at"/>
                            <Column title="claim" dataIndex="is_claim"/>
                            <Column title="redeem" dataIndex="is_redeem"/>
                            <Column title="user type" dataIndex="monthly_saldo_id"/>
                           
                        </Table>
                    </div>
                    <Footer></Footer>
                </Content>
            </Layout>
        )
    }
}