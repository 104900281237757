import React, { Component } from 'react';
import { URL } from '../Components/BaseUrl';
import { Button, message, Icon } from 'antd';

export default class Approval extends Component {
    
    state = {
        userData: {},
        loadingApprove:false,
        loadingReject:false
    }

    onApproved = () => {
        this.setState({ loadingApprove: true });
        const axios = require('axios');
        console.log('props', this.props.id)
        axios.post( URL  +"/api/v1/redeemCheck/list/" + this.props.id + "/approved",
        {},
        {
            headers : {
                Authorization: "Bearer "+ localStorage.getItem("token")
            }
        }
           ).then(response => {
                console.log('response: ', response.data);
                this.props.history.push(
                    {
                        state: response.data.data
                    }
                )
                this.setState({ 
                    ...this.state, 
                    loadingApprove:false
                })
                message.warning(response.data.message)
                window.location.reload();               
            }).catch = (error) => {
                console.log(error.response);
                this.setState({ 
                    ...this.state, 
                    loadingApprove:false})
                message.warning(error.response.message)
                window.location.reload();
            };
    }

    onRejected = () => {
        this.setState({ loadingReject: true });
        const axios = require('axios');
        axios.post( URL  +"/api/v1/redeemCheck/list/" + this.props.id + "/rejected",{},    
        {
                headers : {
                    Authorization: "Bearer "+ localStorage.getItem("token")
                }
            }
           ).then(response => {
            console.log('response: ', response.data);
            this.props.history.push(
                {
                    state: response.data.data
                }
            )
            this.setState({ 
                ...this.state, 
                loadingReject:false
            })
            message.warning(response.data.message)
            window.location.reload();               
        }).catch = (error) => {
            console.log(error.response);
            this.setState({ 
                ...this.state, 
                loadingReject:false})
            window.location.reload();    
            message.warning(error.response.message)
        };
    }
    
    render() {
        return (
            <div>
                <Button 
                    style={{marginRight:5}}
                    type="primary" 
                    onClick={this.onApproved}
                    loading={this.state.loadingApprove}>
                    <Icon type="check"></Icon>
                </Button>
                {/* <Divider type="vertical"/> */}
                <Button 
                    type="danger" 
                    onClick={this.onRejected}
                    loading={this.state.loadingReject}>
                    <Icon type="close"></Icon>
                </Button>
            </div>
        );
    }
}